import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { Button, Text, Flex, Checkbox } from '@rvshare/core-ui';

const CHECKBOX_SIZE = 32;

export const Wrapper = styled.div``;

export const Placeholder = styled(Text)`
  padding-block: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey200};
  color: ${({ theme }) => darken(0.02, theme.colors.grey600)};
`;

export const UpgradesButton = styled(Button).attrs({ type: 'button' })`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey400};
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.shadows.level1};
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0px 1px 0px;
  width: 100%;
  padding: 1rem;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.grey100};
    border-color: ${({ theme }) => theme.colors.primary};
    border-width: 2px;
    margin: 15px 0px -1px 0px;
  }
`;

export const UpgradesSpan = styled.span`
  font-size: ${props => (props.selected ? 'inherit' : '0.9rem')};
  font-weight: ${props => (props.selected ? 100 : 'inherit')};
  color: ${props => (props.selected ? props.theme.colors.grey700 : props.theme.colors.primary)};
`;

export const StyledCheckbox = styled(Checkbox)`
  width: ${CHECKBOX_SIZE}px;
  height: ${CHECKBOX_SIZE}px;
`;

export const CheckboxContainer = styled(Flex)`
  gap: 1rem;
  align-items: ${props => (props.hasDescription ? 'flex-start' : 'center')};
  position: relative;
  min-height: ${CHECKBOX_SIZE}px;

  > label {
    width: 100%;
    position: absolute;
  }

  > div {
    padding-left: 40px;
  }
`;

export const UpgradeTitle = styled.div`
  font-weight: bold;
`;

const textStyles = css`
  text-transform: capitalize;
  word-break: break-word;
`;

export const UpgradeName = styled.span`
  ${textStyles}
`;

export const UpgradeDescription = styled.div`
  color: ${({ theme }) => theme.colors.grey700};
  font-size: 0.875rem;
  ${textStyles}
`;

export const StyledList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;

  li {
    padding: 1rem 0;
  }

  li:first-child {
    padding-top: 0;
  }

  li:last-child {
  }
`;

export const SubTotal = styled(Flex).attrs({ justifyContent: 'space-between', py: '1rem' })`
  border-top: 1px solid ${({ theme }) => theme.colors.grey400};
  font-weight: 800;
`;

export const ModalContainer = styled(Flex).attrs({ flexDirection: 'column' })`
  max-height: 100vh;

  // don't let the modal content grow past the screen and account for top bottom margin
  ${props => props.theme.media.medium`
    max-height: calc(100vh - 56px);
  `}
`;

export const ContentContainer = styled(Flex).attrs({ flexDirection: 'column', p: '1.5rem' })`
  overflow-y: auto;
`;
